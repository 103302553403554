import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CatalogManagementService {
  constructor(private httpClient: HttpClient) {}

  // Categories
  getAllCategories(): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/categories`
    );
  }

  getCategories(type: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/type/list?type=${type}`
    );
  }

  getAllSubCategories(type: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/getCategorySubcategory?type=${type}&returnType=subcategory`
    );
  }

  getSubCategories(payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}subcategories?category=${payload?.catID}`
    );
  }

  getCategorySubcategory(type?: any): Observable<any> {
    if (type == 0 || type == 1) {
      return this.httpClient.get<any>(
        `${environment.baseApiUrl}vendor/getCategorySubcategory?type=${type}&returnType=subcategory`
      );
    } else {
      return this.httpClient.get<any>(
        `${environment.baseApiUrl}vendor/getCategorySubcategory`
      );
    }
  }

  getCommissionRate(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/getCommissionRate/${payload.vendorID}`,
      payload
    );
  }

  // Services
  getServices(payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/services/${payload.vendorID}`
    );
  }

  getServiceList(vendorID: string, payload: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/services/${vendorID}?${payload}`
    );
  }

  enableDisableServices(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/bulkServiceStatusUpdate`,
      payload
    );
  }

  bulkDeleteServices(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/bulkServiceDelete`,
      payload
    );
  }

  getServiceDetails(serviceID: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/service/${serviceID}`
    );
  }

  setService(payload: any): Observable<any> {
    if (payload.serviceID) {
      return this.httpClient.put<any>(
        `${environment.baseApiUrl}vendor/updateService/${payload.serviceID}`,
        payload
      );
    } else {
      return this.httpClient.post<any>(
        `${environment.baseApiUrl}vendor/addService`,
        payload
      );
    }
  }

  uploadServiceImage(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/serviceImage`,
      payload
    );
  }

  getInputTypes(): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/inputtype`
    );
  }

  // Products
  getAllProducts(payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/products/${payload.vendorID}`
    );
  }

  getProducts(vendorID: string, payload: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/products/${vendorID}?${payload}`
    );
  }

  enableDisableProducts(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/bulkProductStatusUpdate`,
      payload
    );
  }

  bulkDeleteProducts(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/bulkProductDelete `,
      payload
    );
  }

  setProduct(payload: any): Observable<any> {
    if (payload.productID) {
      return this.httpClient.put<any>(
        `${environment.baseApiUrl}vendor/updateProduct/${payload.productID}`,
        payload
      );
    } else {
      return this.httpClient.post<any>(
        `${environment.baseApiUrl}vendor/addProduct`,
        payload
      );
    }
  }

  getProductDetails(id: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/product/${id}`
    );
  }

  uploadProductImage(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/productImage`,
      payload
    );
  }

  // Sellers
  getAllSellers(): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/allsellers`
    );
  }

  // Return-request
  getReturnRequestData(vendorID: string, payload: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}returnitem/list/${vendorID}?${payload}`
    );
  }

  statusChangeReturnRequest(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/bulketurnItemStatusUpdate`,
      payload
    );
  }

  getReturnRequestDetail(vendorID: string, requestID: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}returnitem/Details/${requestID}/${vendorID}`
    );
  }

  crteateCreditMemo(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/createCreditNote`,
      payload
    );
  }

  productWiseStatusChangeReturnRequest(
    payload: any,
    requestID: string
  ): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.baseApiUrl}vendor/updateReturnItemStatus/${requestID}`,
      payload
    );
  }
  bulkDeleteReturnItem(payload: any): Observable<any> {
    return this.httpClient.delete<any>(
      `${environment.baseApiUrl}vendor/bulkDeleteProductReturn`,
      payload
    );
  }

  // Cross sell Up-Sell
  getCrossSellUpSellDetails(payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/crossSellUpSell?seller_id=${payload.vendorID}&type=${payload.type}`
    );
  }

  setCrossSellUpSell(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/crossSellUpSell`,
      payload
    );
  }

  // Modification Reason
  getModificationReason(): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/getModificationReasonList`
    );
  }

  // cancellation Reasons
  getCancellationReasons(): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/order/getVendorCancellaionRejectionReasons`
    );
  }
}
