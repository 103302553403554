<!-- ewnewn -->

<div
  class="custom-dialog-modal modal-lg modal-dialog modal-dialog-centered modal-dialog-scrollable"
  role="document"
>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title text-uppercase">Selected Files</h4>
      <button type="button" class="close" (click)="closeModal()">
        <img src="assets/images/close-outline.svg" alt="close" />
      </button>
    </div>
    <div class="modal-body">
      <ul *ngFor="let file of files; let index = index">
        <li class="mb-4">
          {{ index + 1 }}. {{ file.name }} ({{ formatFileSize(file.size) }})
          <button
            class="btn btn-danger ml-2 remove-item-btn"
            (click)="removeFile(file)"
          >
            <img src="assets/images/trash-icon.svg" alt="trash" />
          </button>
        </li>
      </ul>

      <!-- <table class="table">
        <thead>
          <tr>
            <th>Thumbnail</th>
            <th>File Name</th>
            <th>Size</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let file of files">
            <td>
              <img
                *ngIf="isImage(file)"
                [src]="getThumbnail(file)"
                alt="Image Thumbnail"
                width="50"
                height="50"
              />
              <img
                *ngIf="
                  file.type === 'application/pdf' || 
                  file.type === 'application/msword' || 
                  file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || 
                  file.type.startsWith('video/')
                "
                [src]="getThumbnail(file)"
                alt="File Icon"
                width="40"
              />
              <span
                *ngIf="!isImage(file) && 
                        !file.type.startsWith('video/') && 
                        file.type !== 'application/pdf' && 
                        file.type !== 'application/msword' && 
                        file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
              >
                Unsupported File
              </span>
            </td>
            <td>{{ file.name }}</td>
            <td>{{ formatFileSize(file.size) }}</td>
            <td>
              <button class="btn t-danger-btn-outline" (click)="removeFile(file)">Remove</button>
            </td>
          </tr>
        </tbody>
      </table> -->
      <p *ngIf="files.length === 0">No files to display.</p>
    </div>

    <div class="modal-footer">
      <div class="bottom-btnrow">
        <input
          type="file"
          #fileUpdload
          class="d-none"
          multiple
          [id]="'upload-attach'"
          (change)="handleFileInput($event)"
        />
        <button
          type="button"
          class="btn t-primary-btn t-primary-btn-outline attach-btn-size"
          (click)="fileUpdload.click()"
        >
          <img src="assets/images/plus-icon.svg" alt="add" /> Add more
        </button>

        <button
          class="btn t-primary-btn"
          (click)="updateFiles()"
          [disabled]="!hasChanges"
        >
          <img src="assets/images/tickmark-white.svg" alt="send" /> Update
        </button>
      </div>
    </div>
  </div>
</div>
