import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class VendorService {
  socket!: Socket;
  public globalSearchKey: BehaviorSubject<any> = new BehaviorSubject(false);
  public isHeaderEmailNotificationClicked: BehaviorSubject<any> =
    new BehaviorSubject(false);
  private userDetail: BehaviorSubject<any> = new BehaviorSubject('');

  private unreadCountSubject = new BehaviorSubject<number>(0);
  unreadCount$ = this.unreadCountSubject.asObservable();

  // for updating menus
  private menuState = new BehaviorSubject<{
    menuID: number;
    subMenuID: number;
  }>({ menuID: 0, subMenuID: 0 });
  menuState$ = this.menuState.asObservable();

  // for files list popup
  private filesSubject = new BehaviorSubject<File[]>([]); // Observable for file list
  files$ = this.filesSubject.asObservable();

  constructor(private httpClient: HttpClient) {}

  // Login
  login(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/login`,
      payload
    );
  }

  getSiteSettings(): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/getSiteSettings`
    );
  }

  getPageData(payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/pageDetails?type=${payload}`
    );
  }

  rememberMe(emailId: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/rememberme/${emailId}`
    );
  }

  checkLoginUser(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/validateUser`,
      payload
    );
  }

  validateVendor(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/validateUser`,
      payload
    );
  }

  resetpassword(payload: any): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.baseApiUrl}vendor/resetpassword`,
      payload
    );
  }

  sendForgetPwdEmail(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/link/forgetpassword`,
      payload
    );
  }

  checkGoogleOrFacebookUser(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/google/login`,
      payload
    );
  }

  // Register
  register(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/register`,
      payload
    );
  }

  uploadProfileImage(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/profile/image`,
      payload
    );
  }

  uploadLogoImage(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/company/logo`,
      payload
    );
  }

  uploadLicenceImage(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/tradelicense/image`,
      payload
    );
  }

  uploadVATCertificate(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/vatcertificate/image`,
      payload
    );
  }

  // Profile details
  getProfileDetails(vendorID: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/profile/${vendorID}`
    );
  }

  // Dashboard
  getDashboardDetails(payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/dashboard/${payload.vendorID}`
    );
  }

  // Get activeOrderList
  getActiveOrderList(vendorID: string, payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/order/activeOrderlist/${vendorID}?${payload}`
    );
  }

  deactivateAccount(vendorID: string, payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/deactivation/requestVendorDeactivation/${vendorID}`,
      payload
    );
  }

  getHomeCategories(): Observable<any> {
    return this.httpClient.get<any>(`${environment.baseApiUrl}vendor/category`);
  }

  // notifcations
  getAllNotifications(vendorID: string, payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/getNotification/${vendorID}?${payload}`
    );
  }

  getHeaderNotifications(vendorID: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/notifications/${vendorID}`
    );
  }

  getInvoiceNotifications(vendorID: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/invoiceRequestsNotification/${vendorID}`
    );
  }

  socketConnection(id: string): void {
    this.socket = io(environment.backendDomain, {
      auth: {
        id,
      },
      transports: ['websocket', 'polling'], // Ensure all transports are allowed
    });
    // console.log(this.socket);
  }

  socketDisconnect(): void {
    if (this.socket?.connected) {
      this.socket.disconnect();
    }
  }

  markAsReadNotification(payload: any): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.baseApiUrl}vendor/updateNotification`,
      payload
    );
  }

  listenEvent(eventName: string): any {
    return new Observable((subscribe) => {
      this.socket?.on(eventName, (data: any) => {
        subscribe.next(data);
      });
    });
  }

  getGeneralConfig(): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/generalConfig`
    );
  }

  // Contact Us
  contactus(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}contactus`,
      payload
    );
  }

  // Verift OTP
  sendOTP(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/sendOtp`,
      payload
    );
  }

  reSendOTP(emailId: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/resendOtp/${emailId}`
    );
  }

  verifyOTP(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/otpvalidation`,
      payload
    );
  }

  setGlobalSearchKey(key: string): void {
    this.globalSearchKey.next(key);
  }

  getGlobalSearchKey(): Observable<any> {
    return this.globalSearchKey;
  }

  setUserDetail(key: string): void {
    this.userDetail.next(key);
  }

  getUserDetail(): Observable<any> {
    return this.userDetail;
  }

  setHeaderEmailNotification(key: any): void {
    this.isHeaderEmailNotificationClicked.next(key);
  }

  getHeaderEmailNotification(): Observable<any> {
    return this.isHeaderEmailNotificationClicked;
  }

  setUnreadCount(count: number): void {
    this.unreadCountSubject.next(count);
  }

  updateMenuState(menuID: number, subMenuID: number): void {
    this.menuState.next({ menuID, subMenuID });
  }

  setFiles(files: File[]): void {
    this.filesSubject.next(files); // Update the file list
  }

  getFiles(): File[] {
    return this.filesSubject.getValue(); // Get the current file list
  }

  clearFiles(): void {
    this.filesSubject.next([]); // Clear the files
  }
}
