import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  Component,
  Inject,
  OnInit,
  Renderer2,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';

import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Select2OptionData } from 'ng-select2';
import { debounceTime, takeUntil } from 'rxjs';
import { GeneralService } from 'src/app/core/services/general.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { SupportService } from 'src/app/core/services/support.service';
import { SubscriptionDisposer } from 'src/app/shared/helpers/subscription-disposer';
import { getErrorReason, isValidFile } from './file-upload-util';
import { OpenModalConfig } from '@app/shared/directives/open-modal.directive';
import { TicketInvoiceRequestModalComponent } from '../ticket-invoice-request-modal/ticket-invoice-request-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ViewFilesModalComponent } from '../view-files-modal/view-files-modal.component';
import { VendorService } from '@app/core/services/vendor.service';

@Component({
  selector: 'app-add-new-ticket-modal',
  templateUrl: './add-new-ticket-modal.component.html',
  styleUrls: ['./add-new-ticket-modal.component.scss'],
})
export class AddNewTicketModalComponent
  extends SubscriptionDisposer
  implements OnInit
{
  @ViewChild('viewFileModal') viewFileModal: any;

  config!: AngularEditorConfig;

  couponID: any = 0;

  allOrderList: Array<Select2OptionData> = [];
  allItemList: any = [];
  itemObj = {};
  items: any = [];
  ticketData = new UntypedFormGroup({
    // ticketType: new UntypedFormControl('', [Validators.required]),
    subject: new UntypedFormControl('', [Validators.required]),
    description: new UntypedFormControl('', [Validators.required]),
    order: new UntypedFormControl('', [Validators.required]),
    item: new UntypedFormControl({ value: '', disabled: true }, [
      Validators.required,
    ]),
  });

  selectedMode: string = '4';
  modeList: Select2OptionData[] = [
    {
      id: '0',
      text: 'Raise a Query',
    },
    {
      id: '1',
      text: 'Raise a Dispute',
    },
  ];

  selectedFileCount = 0;
  ticketFormData: FormData = new FormData();
  allowedFileExt =
    'png|.jpeg|.jpg|.doc|.docx|.pdf|.mp4|.mov|.avi|.wmv|.webm|.ogg|.mpeg|.3gpp';
  maxFileSize = 1024 * 1024 * 5;

  ticketToUpload: any[] = [];
  ticketImageUrl: any;
  ticketeUploadInput: any;

  chatModalObject!: any;
  customChatModalData: OpenModalConfig = {
    modalComponent: TicketInvoiceRequestModalComponent,
    modalData: {
      ...this.chatModalObject,
    },
    width: '83rem',
  };

  viewFilesModalData: OpenModalConfig = {
    modalComponent: ViewFilesModalComponent,
    modalData: {
      page: 'service',
    },
    width: '35rem',
  };
  showTooltip = false;

  @Output() tabChange: EventEmitter<string> = new EventEmitter();

  constructor(
    public dialogRef: DialogRef<any>,
    @Inject(DIALOG_DATA)
    public data: {
      couponID: any;
    },
    private generalService: GeneralService,
    private supportService: SupportService,
    private storageService: StorageService,
    private vendorService: VendorService,
    private dialog: MatDialog,
    private renderer: Renderer2
  ) {
    super();

    if (data?.couponID) {
      this.couponID = data.couponID;
    }
  }

  get f() {
    return this.ticketData.controls;
  }

  ngOnInit(): void {
    this.vendorService.clearFiles(); // Clear any previous files

    this.config = {
      ...this.generalService.getEditorConfig(),
      toolbarPosition: 'bottom',
      toolbarHiddenButtons: [['link', 'unlink']],
    };
    this.getOrderReferenceList();

    // this.ticketData.get('ticketType').valueChanges.subscribe((value) => {
    //   this.switchForms('');
    // });

    // this.ticketData.get('order').valueChanges.subscribe((value) => {
    //   console.log('in subscribe ')
    //   this.getItemReferenceList(value);
    // });

    // Track last value of order to prevent redundant calls
    let previousOrderValue = this.ticketData.get('order').value;

    this.ticketData
      .get('order')
      .valueChanges.pipe(debounceTime(300)) // Adds a delay to prevent rapid triggering
      .subscribe((currentOrderValue) => {
        if (currentOrderValue !== previousOrderValue) {
          previousOrderValue = currentOrderValue;
          this.getItemReferenceList(currentOrderValue); // Call only if the value truly changed
        }
      });

    this.initalizeFrom();

    // Subscribe to the updated file list
    this.vendorService.files$.subscribe((files) => {
      console.log('Old files: ', this.ticketToUpload);
      console.log('New files: ', files);
      this.ticketToUpload = files;

      // Remove all previous 'ticketfiles' entries from ticketFormData
      this.clearTicketFiles();

      // Add updated files to ticketFormData
      files.forEach((file) => {
        this.ticketFormData.append('ticketfiles', file);
      });
      this.selectedFileCount = files.length;
      console.log('Updated files in shared component:', this.ticketToUpload);
    });
  }

  // Helper method to remove all ticketfiles from ticketFormData
  private clearTicketFiles(): void {
    const newFormData = new FormData();

    // Retain all entries except 'ticketfiles'
    this.ticketFormData.forEach((value, key) => {
      if (key !== 'ticketfiles') {
        newFormData.append(key, value as Blob);
      }
    });

    // Replace the ticketFormData with the new one
    this.ticketFormData = newFormData;
  }

  initalizeFrom() {
    // this.ticketData.get('subject').disable();
    // this.ticketData.get('description').disable();
    // this.config.editable = false;

    // this.ticketData.get('order').clearValidators();
    // this.ticketData.get('item').clearValidators();
    this.ticketData.get('order').disable();
    this.ticketData.get('item').disable();
  }

  getOrderReferenceList(): void {
    const payload = {
      seller_id: this.storageService.getCookie('userID'),
    };
    this.supportService
      .getOrderReferenceList(payload)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        if (response && response.data) {
          this.allOrderList = response?.data;
        }
      });
  }

  getItemReferenceList(orderID: any): void {
    this.allItemList = [];
    if (orderID) {
      this.ticketData.get('item').enable();
    } else {
      this.ticketData.get('item').disable();
    }
    const payload = {
      seller_id: this.storageService.getCookie('userID'),
      order_id: orderID ? orderID : '',
    };
    this.supportService
      .getOrderWiseItemReferenceList(payload)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        if (response && response.data) {
          this.items = response?.data;
          if (this.items?.length > 0) {
            this.allItemList = this.items?.map((item: any) => {
              item.text = item.variants?.name
                ? item.name + ' (' + item.variants?.name + ')'
                : item?.name;
              return {
                text: item.variants?.name
                  ? item.name + ' (' + item.variants?.name + ')'
                  : item?.name,
                id: item.variants?.name
                  ? item.name + ' (' + item.variants?.name + ')'
                  : item?.name,
              };
            });
          }
          // If only one item, set it as the selected item
          if (this.allItemList.length === 1) {
            this.ticketData.get('item').setValue(this.allItemList[0].id);
          }
        }
      });
  }

  getSelectedItem(data: any): void {
    this.itemObj = this.items?.filter((item: any) => {
      if (item.text == data) {
        return item;
      }
    })?.[0];
  }

  save(): void {
    //enable descrition
    // this.ticketData.get('description').enable();

    console.log('this.ticketData.invalid');
    console.log(this.ticketData.invalid);
    console.log('this.ticketData');
    console.log(this.ticketData);
    // console.log(this.ticketData)
    if (this.ticketData.invalid) {
      this.ticketData.markAllAsTouched();
    } else {
      let payload: any = {
        subject: this.ticketData.value?.subject,
        description: this.ticketData.value?.description,
        // order: this.ticketData.value?.order,
        seller: this.storageService.getCookie('userID'),
        // item: this.itemObj ? this.itemObj : {},
        status: 0, // 0 => open, 1=> close
        tickets_by: 1, //0 => customer to seller, 1=> seller to admin
        // ticketType: this.selectedMode === '1' ? 3 : 4, // 3 for dispute, 4 for query
        ticketType: Number(this.selectedMode), // 3 for dispute, 4 for query
      };

      console.log(payload);

      // Only include order & item if raising a dispute
      if (this.selectedMode === '3') {
        payload.order = this.ticketData.value?.order;
        payload.item = this.itemObj ? this.itemObj : {};
      }
      // console.log(payload);

      this.supportService.addTicket(payload).subscribe(
        (response) => {
          if (response?.success) {
            this.generalService.displaySuccess(response?.message);

            // After saving the ticket, emit the tab change
            this.supportService.emitTabChange('open');

            if (this.selectedFileCount > 0) {
              this.uploadFiles(response?.data?.ticket_id);
            } else {
              this.close({ onSaveClose: true });
            }
          } else {
            // handle ticket already exists & ticket closed
            console.log('response?.data');
            console.log(response?.data);
            // if already exists
            if (response?.data?.isOpen && !response?.data?.isClosed) {
              this.generalService
                .alertDialog(
                  'Ticket already exists',
                  `We already having existing dispute for the same order ID please find ticket number for your reference: <b>${response?.data?.ticket_number}</b>`
                )
                .then((result: any) => {
                  if (result.isConfirmed) {
                    this.close({ onSaveClose: true });
                    this.openTicketModal(response?.data?.ticketId);

                    this.supportService.emitTabChange('open');
                  }

                  if (result.isDismissed) {
                    this.close({ onSaveClose: true });
                  }
                });
            }

            // if ticket closed
            if (!response?.data?.isOpen && response?.data?.isClosed) {
              this.generalService
                .customTicketDialog(
                  'Ticket is closed',
                  `Since there is already an existing dispute for the same order ID, would you like to raise a new dispute or reopen the existing one?`
                )
                .then((result: any) => {
                  console.log('Dialog Result:', result); // Log the result to see if it's triggered properly

                  if (result.isDenied) {
                    // call reopen api here
                    console.log(
                      'Reopening ticket with ID:',
                      response?.data?.ticketId
                    ); // Verify the ticketId

                    this.reopenTicket(response?.data?.ticketId);
                    // this.openTicketModal(response?.data?.ticketId);

                    this.supportService.emitTabChange('open');
                  }

                  if (result.isConfirmed) {
                    console.log('User chose to create a new ticket');

                    // create new with same api isForce=true
                    payload.isForce = true;

                    this.supportService.addTicket(payload).subscribe(
                      (response) => {
                        if (response?.success) {
                          this.generalService.displaySuccess(response?.message);
                          if (this.selectedFileCount > 0) {
                            this.uploadFiles(response?.data?.ticket_id);
                          } else {
                            this.close({ onSaveClose: true });
                          }

                          this.supportService.emitTabChange('open');
                        }
                      },
                      (err) => {
                        this.generalService.displayError(err.error.message);
                      }
                    );
                  }
                  this.close({ onSaveClose: true });
                });
            }
          }
        },
        (err) => {
          this.generalService.displayError(err.error.message);
        }
      );
    }
  }

  reopenTicket(ticketId: any): void {
    const payload = {
      ticketID: ticketId,
    };
    this.supportService.reopenTicket(payload).subscribe(
      (res) => {
        if (res) {
          if (res?.success) {
            this.generalService.displaySuccess(res?.message);
          } else {
            this.generalService.displayError(res?.message);
          }
        }
      },
      (error) => {
        this.generalService.displayError(error?.error?.message);
      }
    );

    this.openTicketModal(ticketId);
  }

  openTicketModal(ticketID: any): void {
    const payload = {
      ticketID: ticketID,
    };
    this.supportService
      .getTicketDetails(payload)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res) => {
          if (res) {
            let ticketDetailsv2 = res?.data;
            ticketDetailsv2?.ticketfiles?.map((item: any) => {
              let arr = [];
              arr = item.file_name?.split('.');
              item.file_type = arr?.[arr.length - 1];
            });
            const modalConfig = this.getInvoiceModalConfig(
              ticketID,
              ticketDetailsv2
            );
            this.openModal(modalConfig);
          }
        },
        (error) => {
          this.generalService.displayError(error?.error?.message);
        }
      );
  }

  getInvoiceModalConfig(id: any, ticket: any) {
    // this.ticketID = id;
    this.chatModalObject = {
      ...ticket,
      // isVendor: this.isVendor,
      isOpen: 'open',
    };

    this.customChatModalData = {
      ...this.customChatModalData,
      modalData: {
        chatModalObject: { ...this.chatModalObject },
        ticketID: id,
      },
    };

    return this.customChatModalData;
  }

  // openModal(config: OpenModalConfig): void {
  //   const dialogRef = this.dialog.open(config.modalComponent, {
  //     data: config.modalData,
  //     width: config.width,
  //     maxHeight: '90vh',
  //   });
  //   dialogRef.afterOpened().subscribe(() => {
  //     const container = document.querySelector(
  //       '.mat-dialog-container'
  //     ) as HTMLElement;
  //     if (container) {
  //       container.style.background = 'transparent';
  //       container.style.boxShadow = 'none';
  //       container.style.overflowY = 'auto';
  //       container.style.overflowY = 'auto'; // Force overflow-y
  //       container.style.padding = '0';
  //     }

  //     // for margin and scroll
  //     const modalContainer = document.querySelector(
  //       '.custom-dialog-modal'
  //     ) as HTMLElement;
  //     if (modalContainer) {
  //       modalContainer.classList.add('opened-programatically'); // Add class when opened
  //       // modalContainer.style.margin = '0'; // Additional inline styling if needed
  //     }
  //   });

  //   dialogRef.afterClosed().subscribe(() => {
  //     const container = document.querySelector(
  //       '.custom-dialog-modal'
  //     ) as HTMLElement;
  //     if (container) {
  //       container.classList.remove('opened-programatically'); // Remove class when closed
  //     }
  //   });
  // }
  //   openModal(config: OpenModalConfig): void {
  //     const dialogRef = this.dialog.open(config.modalComponent, {
  //       data: config.modalData,
  //       width: config.width,
  //       maxHeight: '90vh',
  //     });

  //     dialogRef.afterOpened().subscribe(() => {
  //       const container = document.querySelector('.mat-dialog-container') as HTMLElement;
  //       if (container) {
  //         container.style.background = 'transparent';
  //         container.style.boxShadow = 'none';
  //         container.style.overflowY = 'auto';
  //         container.style.padding = '0';
  //       }

  //       // Add custom class for margin and scroll styling
  //       const modalContainer = document.querySelector('.custom-dialog-modal') as HTMLElement;
  //       if (modalContainer) {
  //         modalContainer.classList.add('opened-programatically');
  //       }
  //     });

  //     dialogRef.afterClosed().subscribe(() => {
  //       // Remove custom class when the modal is closed
  //       const modalContainer = document.querySelector('.custom-dialog-modal') as HTMLElement;
  //       if (modalContainer) {
  //         modalContainer.classList.remove('opened-programatically');
  //       }
  //     });
  // }

  openModal(config: OpenModalConfig): void {
    const dialogRef = this.dialog.open(config.modalComponent, {
      data: config.modalData,
      width: config.width,
      maxHeight: '90vh',
    });

    dialogRef.afterOpened().subscribe(() => {
      const container = document.querySelector(
        '.mat-dialog-container'
      ) as HTMLElement;
      if (container) {
        this.renderer.setStyle(container, 'background', 'transparent');
        this.renderer.setStyle(container, 'boxShadow', 'none');
        this.renderer.setStyle(container, 'overflowY', 'auto');
        this.renderer.setStyle(container, 'padding', '0');
      }

      const modalContainer = document.querySelector(
        '.custom-dialog-modal'
      ) as HTMLElement;
      if (modalContainer) {
        this.renderer.addClass(modalContainer, 'opened-programatically');
      }
    });

    dialogRef.afterClosed().subscribe(() => {
      const modalContainer = document.querySelector(
        '.custom-dialog-modal'
      ) as HTMLElement;
      if (modalContainer) {
        this.renderer.removeClass(modalContainer, 'opened-programatically');
      }
    });
  }

  uploadFiles(ticketId: any): void {
    if (this.ticketToUpload.length > 0) {
      this.ticketFormData.append('ticket_id', ticketId); // Attach the ticket ID to the FormData

      // Call the file upload API
      this.supportService.uploadTicketFile(this.ticketFormData).subscribe(
        (res) => {
          if (res?.sucess) {
            this.generalService.displaySuccess(res?.message);
            this.close({ onSaveClose: true });
            this.resetFilesData();
          } else {
            this.generalService.displayError(res?.message);
            this.close({ onSaveClose: true });
          }
        },
        (error) => {
          this.generalService.displayError(error?.error?.message);
        }
      );
    }
  }

  close(data?: any): void {
    this.vendorService.clearFiles(); // Reset the files in the vendor service
    this.dialogRef.close(data);
  }

  resetFilesData(): void {
    console.log('files reset success');
    // this.selectedFileCount = 0;
    this.ticketToUpload = [];
    this.selectedFileCount = 0;
    this.ticketFormData = new FormData(); // Clear FormData
    console.log(this.ticketToUpload);
    console.log(this.selectedFileCount);
    console.log(this.ticketFormData);
  }

  resetForm(): void {
    // Manually reset each control to avoid persistence issues
    this.ticketData.get('subject').setValue('');
    this.ticketData.get('description').setValue('');
    this.ticketData.get('order').setValue('');
    this.ticketData.get('item').setValue('');
    this.ticketData.markAsPristine(); // Marks the form as untouched
    this.ticketData.markAsUntouched();

    // Clear any other data related to file uploads
    this.ticketToUpload = [];
    this.selectedFileCount = 0;
    this.ticketFormData = new FormData();
    this.ticketImageUrl = null;
  }

  switchForms(selectedMode: string): void {
    // this.selectedMode = this.ticketData.get('ticketType')?.value;
    this.selectedMode = selectedMode;
    // console.log('selectedMode');
    // console.log(selectedMode);
    // this.ticketData.get('subject').enable();
    // this.config.editable = true;

    if (this.selectedMode === '3') {
      // Dispute
      this.ticketData.get('order').setValidators([Validators.required]);
      this.ticketData.get('item').setValidators([Validators.required]);
      this.ticketData.get('order').enable();
      this.ticketData.get('item').enable();
    } else {
      // Query
      this.ticketData.get('order').clearValidators();
      this.ticketData.get('item').clearValidators();
      this.ticketData.get('order').disable();
      this.ticketData.get('item').disable();
    }

    // Update validation status after changing validators
    this.ticketData.get('order').updateValueAndValidity();
    this.ticketData.get('item').updateValueAndValidity();

    // reset form on change
    this.resetForm();
  }

  handleFileInput(event: any): void {
    // add a check for existing image (length)

    if (this.maximumFileValidation(event)) {
      return;
    }

    // this.ticketFormData = new FormData();
    // this.ticketId = id;
    const files = event.target.files;
    // this.selectedFileCount = 0;

    if (files && files.length > 0) {
      if (files.length <= 10) {
        for (const file of files) {
          const isDuplicate = this.ticketToUpload.some(
            (existingFile) =>
              existingFile.name === file.name && existingFile.size === file.size
          );

          if (isDuplicate) {
            this.generalService.displayError(
              `The file "${file.name}" is already added.`
            );
            continue;
          }

          console.log('max: ' + this.maxFileSize);
          const fileDetail = isValidFile(
            file,
            this.maxFileSize,
            this.allowedFileExt,
            ''
          );
          if (fileDetail.validity) {
            this.selectedFileCount = this.selectedFileCount + 1;
            this.ticketFormData.append('ticketfiles', file);
            this.ticketToUpload.push(file);
          } else {
            const reason = getErrorReason(fileDetail.fileData);
            this.generalService.displayError(reason);
          }
        }
        this.processFiles();
      } else {
        this.generalService.displayError('Maximum 10 files are allowed');
      }
    } else {
      this.generalService.displayError('Please select files');
    }
    // Reset the file input value
    event.target.value = '';
  }

  processFiles(): void {
    this.ticketToUpload.forEach((file: File) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.ticketImageUrl = event.target.result;
        if (this.ticketeUploadInput) {
          this.ticketeUploadInput.nativeElement.value = '';
        }
      };
      reader.readAsDataURL(file);
    });
  }

  maximumFileValidation(event: any): boolean {
    // console.log(this.ticketToUpload)
    // if (this.ticketToUpload){
    // if(this.ticketToUpload.length + event.target.files.length > 10) {
    //   alert(this.ticketToUpload.length)
    //   this.generalService.displayError('Maximum 10 files are allowed');
    //   return true;
    // }
    // }

    if (
      this.ticketToUpload.length > 10 ||
      this.ticketToUpload.length + event.target.files.length > 10
    ) {
      // alert(this.ticketToUpload.length)
      this.generalService.displayError('Maximum 10 files are allowed');
      return true;
    }
    return false;
  }

  getViewFilesModalConfig() {
    this.viewFilesModalData = {
      ...this.viewFilesModalData,
      modalData: {
        // need to send files here
        // test: this.ticketFormData,
        files: this.ticketToUpload || [],
      },
    };
    return this.viewFilesModalData;
  }

  openFileViewer(): void {
    alert(1);
    this.dialog.open(ViewFilesModalComponent, {
      data: {
        files: this.ticketFormData,
      },
      width: '60%',
      maxHeight: '80vh',
    });
  }

  closeViewFileModal(event: any): void {
    if (event) {
      setTimeout(() => {
        this.viewFileModal?.nativeElement?.click();
      });
    }
  }

  get getUploadedFileName() {
    const allFiles = this.ticketFormData.getAll('ticketfiles');
    const name = allFiles.map((item) => item['name']).join(',\n');
    return name;
  }
}
