<div
  class="custom-dialog-modal modal-lg modal-dialog modal-dialog-centered modal-dialog-scrollable"
  role="document"
>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">New Ticket</h4>
      <button type="button" class="close" (click)="close()">
        <img src="assets/images/close-outline.svg" alt="close" />
      </button>
    </div>
    <div class="modal-body">
      <div class="add-new-ticket-block" [formGroup]="ticketData">
        <div class="form-group">
          <div class="left-title mb-2">
            <label>Ticket Type</label>
          </div>
          <!-- tabs - start -->
          <div class="coupon-type-tabs new-ticket-tabs">
            <ul class="tabs">
              <li
                class="tab-link"
                [ngClass]="{ active: selectedMode === '4' }"
                (click)="switchForms('4')"
              >
                <a href="javascript:void(0);">Raise a Query</a>
              </li>
              <li
                class="tab-link"
                [ngClass]="{ active: selectedMode === '3' }"
                (click)="switchForms('3')"
              >
                <a href="javascript:void(0);">Raise a Dispute</a>
              </li>
            </ul>
          </div>
          <!-- tabs - end -->
          <!-- <div class="right-field">
            <div class="lg-radiobox">
              <div
                class="c-radiobox circle-radio"
                *ngFor="let m of modeList; let i = index"
              >
                <input
                  type="radio"
                  name="ticketType"
                  formControlName="ticketType"
                  id="mode{{ i }}"
                  [value]="m.id"
                  formControlName="ticketType"
                  (change)="switchForms('')"
                />
                <label for="mode{{ i }}">{{ m.text }}</label>
              </div>
            </div>
          </div> -->
        </div>

        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Subject"
            formControlName="subject"
          />
          <div
            *ngIf="f['subject'].invalid && f['subject'].touched"
            class="cross-validation-error-message"
          >
            <div
              *ngIf="f['subject'].errors && f['subject'].errors['required']"
              class="required-note text-danger"
            >
              Subject is required.
            </div>
          </div>
        </div>

        <div class="form-group" *ngIf="selectedMode === '3'">
          <ng-select2
            class="select2-control"
            name="order"
            id="allOrderListID"
            [data]="allOrderList"
            [placeholder]="'Select Order / Reference Number'"
            formControlName="order"
          >
          </ng-select2>
          <div
            *ngIf="f['order'].invalid && f['order'].touched"
            class="cross-validation-error-message"
          >
            <div
              *ngIf="f['order'].errors && f['order'].errors['required']"
              class="required-note text-danger"
            >
              Order/ Reference number is required.
            </div>
          </div>
        </div>

        <div class="form-group" *ngIf="selectedMode === '3'">
          <ng-select2
            class="select2-control"
            name="item"
            id="allItemListID"
            [data]="allItemList"
            (valueChanged)="getSelectedItem($event)"
            [placeholder]="'Select Item'"
            formControlName="item"
          >
          </ng-select2>
          <div
            *ngIf="f['item'].invalid && f['item'].touched"
            class="cross-validation-error-message"
          >
            <div
              *ngIf="f['item'].errors && f['item'].errors['required']"
              class="required-note text-danger"
            >
              Item is required.
            </div>
          </div>
        </div>

        <div class="form-group">
          <angular-editor formControlName="description" [config]="config">
          </angular-editor>

          <!-- attach file -->
          <div class="row attach-button mt-2">
            <div class="col-md-2 attach-col">
              <div class="d-flex align-items-center">
                <label
                  class="action-link upload-attachment"
                  [for]="'upload-attach'"
                >
                  <input
                    type="file"
                    #fileUpdload
                    class="d-none"
                    multiple
                    [id]="'upload-attach'"
                    (change)="handleFileInput($event)"
                  />
                  <button
                    type="button"
                    class="btn t-primary-btn attach-btn-size"
                    (click)="fileUpdload.click()"
                    [title]="getUploadedFileName"
                    [disabled]="!selectedMode"
                  >
                    Attach file(s)
                  </button>

                  <!-- <span *ngIf="selectedFileCount > 0" class="count">
                  {{ selectedFileCount }}
                </span> -->
                </label>

                <div
                  class="cst-tooltip-container"
                  (mouseover)="showTooltip = true"
                  (mouseout)="showTooltip = false"
                >
                  <img
                    class="info-icon"
                    src="assets/images/i-info-icon.svg"
                    alt="i-info-tooltip"
                  />
                  <div
                    class="cst-tooltip cst-tooltip-right"
                    *ngIf="showTooltip"
                  >
                    Note: png, jpg, mp4, doc, & pdf files are supported. File
                    size should be less than 5mb.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <p class="mt-2 ml-1 mb-1">
            <a
              class="view-attach-btn"
              *ngIf="selectedFileCount > 0"
              href="javascript: void(0)"
              appOpenModal
              [config]="viewFilesModalData"
              (onModalClose)="closeViewFileModal($event)"
              >View Attached files ({{ selectedFileCount }})
            </a>
          </p>
          <!-- <p class="mt-2 ml-1 small-note">
            Note: png, jpg, mp4, doc, & pdf files are supported. File size
            should be less than 5mb.
          </p> -->
        </div>

        <!-- <div class="empty-block" *ngIf="!selectedMode"></div> -->
      </div>
    </div>
    <div class="modal-footer" *ngIf="selectedMode !== ''">
      <div class="bottom-btnrow">
        <button
          class="btn t-primary-btn for-disabled"
          (click)="save()"
          [disabled]="
            ticketData.get('description').invalid ||
            !ticketData.get('description').value.trim()
          "
        >
          <img src="assets/images/paper-plane-outline.svg" alt="send" /> Send
        </button>
      </div>
    </div>
  </div>
</div>

<!-- View file modal -->
<div
  class="d-none"
  #viewFileModal
  appOpenModal
  [config]="getViewFilesModalConfig()"
  (onModalClose)="closeViewFileModal($event)"
></div>
