import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { RouterModule } from '@angular/router';

import { NgIdleModule } from '@ng-idle/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { SafePipeModule } from 'safe-pipe';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { AppSettingsInitializer } from './core/initializer/app-settings.initializer';
import { UserIdentityInitializer } from './core/initializer/user-identity.initializer';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';

import { SharedModule } from './shared/shared.module';
import { GlobalErrorHandler } from './core/services/global-error-handler.service';
import { MatTableModule } from '@angular/material/table';

export function userInitializerService(
  userInfoInitService: AppSettingsInitializer
): any {
  return (): Promise<boolean> => userInfoInitService.Init();
}
export function userIdentityInitializerService(
  userIdentityInitService: UserIdentityInitializer
): any {
  return (): Promise<boolean> => userIdentityInitService.Init();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    RouterModule,
    HttpClientModule,
    SafePipeModule,
    NgxSpinnerModule.forRoot({ type: 'ball-pulse-sync' }),
    ToastrModule.forRoot({
      preventDuplicates: true,
      includeTitleDuplicates: true,
    }),
    NgIdleModule.forRoot(),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: () => userInitializerService,
      deps: [AppSettingsInitializer],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => userIdentityInitializerService,
      deps: [UserIdentityInitializer],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
