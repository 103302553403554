<div class="add-new-ticket-block">
  <div class="ticket-chat-thread" *ngIf="msgList.length">
    <!-- <ng-container *ngFor="let item of msgList">
      <div
        class="thread-block"
        [ngClass]="vendorId == item?.from_vendor ? 'send-msg' : 'recive-msg'"
        
      >
        <div class="date">{{ item.time | date : 'dd/MM/yyyy, h:mm a' }}</div>
        <h4 class="title">{{ item.title }}</h4>
        <p class="desc" [innerHtml]="item.description"></p>
        <div
          class="file-attached-group mt-2"
          *ngIf="item?.ticket_files?.length > 0"
        >
          <div
            class="file-attached-card"
            *ngFor="let img of item?.ticket_files"
          >

          {{img.file_type}}
            <div class="file-attached-item">
              <div class="file-type-info">
                <div class="file-icon">
                  <img
                    *ngIf="img.file_type === 'csv'"
                    src="assets/images/CSV.svg"
                    alt="file-type"
                  />
                  <img
                    *ngIf="img.file_type === 'pdf'"
                    src="assets/images/PDF.svg"
                    alt="file-type"
                  />
                  <img
                    *ngIf="img.file_type === 'text'"
                    src="assets/images/TXT.svg"
                    alt="file-type"
                  />
                  <img
                    *ngIf="img.file_type === 'jpeg' || img.file_type === 'jpg' || img.file_type === 'png'"
                    src="img"
                    alt="file-type"
                  />
                  <img
                    *ngIf="img.file_type === 'zip'"
                    src="assets/images/ZIP.svg"
                    alt="file-type"
                  />
                </div>
                <div class="file-type-name">
                  {{ img?.file_type }}
                </div>
              </div>
              <div class="file-detail-info">
                <div class="name">{{ img?.file_name }}</div>
                <div class="size">{{ img?.file_size }}KB</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container> -->
    <ng-container *ngFor="let item of msgList">
      <div
        class="thread-block"
        [ngClass]="vendorId == item?.from_vendor ? 'send-msg' : 'recive-msg'"
      >
        <div class="date">{{ item.time | date : 'dd/MM/yyyy, h:mm a' }}</div>
        <h4 class="title">{{ item.title }}</h4>
        <p class="desc p-2" [innerHtml]="item.description"></p>
        <div class="file-attached-group mt-2" *ngIf="item.files.length > 0">
          <div class="img-item mr-2" *ngFor="let file of item.files">
            <ng-container
              *ngIf="
                ['jpeg', 'jpg', 'png'].includes(file.file_type);
                else fileType
              "
            >
              <div class="img-url">
                <a
                  type="button"
                  class="download-btn"
                  (click)="
                    generalService.downloadFile(
                      file.img_url,
                      file.file_name,
                      file.trimmed_file_name
                    )
                  "
                >
                  <img
                    class="ticket-img"
                    [src]="file.img_url"
                    alt="ticket-img"
                  />
                </a>
              </div>
            </ng-container>
            <ng-template #fileType>
              <div class="file-attached-card">
                <a
                  type="button"
                  class="download-btn"
                  (click)="
                    generalService.downloadFile(
                      file.img_url,
                      file.file_name,
                      file.trimmed_file_name
                    )
                  "
                >
                  <div class="file-attached-item">
                    <div class="file-type-info">
                      <div class="file-icon">
                        <img
                          *ngIf="file.file_type === 'csv'"
                          src="assets/images/CSV.svg"
                          alt="file-type"
                        />
                        <img
                          *ngIf="file.file_type === 'pdf'"
                          src="assets/images/PDF.svg"
                          alt="file-type"
                        />
                        <img
                          *ngIf="file.file_type === 'txt'"
                          src="assets/images/TXT.svg"
                          alt="file-type"
                        />
                        <img
                          *ngIf="file.file_type === 'zip'"
                          src="assets/images/ZIP.svg"
                          alt="file-type"
                        />
                        <img
                          *ngIf="
                            !['csv', 'pdf', 'txt', 'zip'].includes(
                              file.file_type
                            )
                          "
                          src="assets/images/pin-attach-icon.svg"
                          alt="file-type"
                        />
                      </div>
                    </div>
                    <div class="file-detail-info">
                      <div class="name">{{ file.trimmed_file_name }}</div>
                    </div>
                  </div>
                </a>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- here status from chat modal: <h2>{{chatModalObject?.status}}</h2> -->
  <!-- chatModalObject?.status !== 1   //0=open //1=closed //2=reOpen -->

  <div
    class="form-group mb-0"
    [formGroup]="replyData"
    *ngIf="isOpen !== 'closed'"
  >
    <angular-editor formControlName="msg" [config]="config"> </angular-editor>

    <!-- attach file -->
    <div class="row attach-button mt-2">
      <div class="col-md-2 attach-col">
        <div class="d-flex align-items-center">
          <label
            class="action-link upload-attachment d-flex align-items-center"
            [for]="'upload-attach'"
          >
            <input
              type="file"
              #fileUpdload
              class="d-none"
              multiple
              [id]="'upload-attach'"
              (change)="handleFileInput($event)"
            />
            <button
              type="button"
              class="btn t-primary-btn attach-btn-size"
              (click)="fileUpdload.click()"
              [title]="getUploadedFileName"
            >
              Attach file(s)
            </button>

            <!-- <span *ngIf="selectedFileCount > 0" class="count">
            {{ selectedFileCount }}
          </span> -->
          </label>
          <div
            class="cst-tooltip-container"
            (mouseover)="showTooltip = true"
            (mouseout)="showTooltip = false"
          >
            <img
              class="info-icon"
              src="assets/images/i-info-icon.svg"
              alt="i-info-tooltip"
            />
            <div class="cst-tooltip cst-tooltip-right" *ngIf="showTooltip">
              Note: png, jpg, mp4, doc, & pdf files are supported. File size
              should be less than 5mb.
            </div>
          </div>
        </div>
      </div>
    </div>

    <p class="mt-2 ml-1 mb-1">
      <a
        class="view-attach-btn"
        *ngIf="selectedFileCount > 0"
        href="javascript: void(0)"
        appOpenModal
        [config]="viewFilesModalData"
        (onModalClose)="closeViewFileModal($event)"
        >View Attached files ({{ selectedFileCount }})
      </a>
    </p>
    <!-- <p class="mt-2 ml-1 small-note">
      Note: png, jpg, mp4, doc, & pdf files are supported. File size should be
      less than 5mb.
    </p> -->
    <!-- <div
      *ngIf="f['msg'].invalid && f['msg'].touched"
      class="cross-validation-error-message"
    >
      <div
        *ngIf="f['msg'].errors && f['msg'].errors['required']"
        class="required-note text-danger"
      >
        Msg is required.
      </div>
    </div> -->
  </div>
  <div class="bottom-btnrow mt-0" #replyDiv *ngIf="isOpen !== 'closed'">
    <button
      class="btn t-primary-btn for-disabled"
      (click)="sendMessage()"
      [disabled]="
        replyData.get('msg').invalid || !replyData.get('msg').value.trim()
      "
    >
      <img src="assets/images/paper-plane-outline.svg" alt="send" /> Send
    </button>
  </div>
</div>

<!-- View file modal -->
<div
  class="d-none"
  #viewFileModal
  appOpenModal
  [config]="getViewFilesModalConfig()"
  (onModalClose)="closeViewFileModal($event)"
></div>
