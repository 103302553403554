import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { IUserInfo } from '@app/core/model/common-model';
import { CredentialService } from '@app/core/services/credential.service';

import { AngularEditorConfig } from '@kolkov/angular-editor';
import { GeneralService } from 'src/app/core/services/general.service';

@Component({
  selector: 'app-package-configuration-modal',
  templateUrl: './package-configuration-modal.component.html',
})
export class PackageConfigurationModalComponent implements OnInit {
  @Output() editPackageOptionValuesData = new EventEmitter();
  @Output() isClosed = new EventEmitter();
  selectedPackageData: any;
  packageOptionValues?: any;
  packageOptionID?: any;
  categoryID: string;
  defaultPackage: any;
  taxPercentage: number;
  userDetails: IUserInfo;
  removeTaxPercentage = true;
  packageData = new UntypedFormGroup({
    title: new UntypedFormControl('', [Validators.required]),
    sale_price: new UntypedFormControl('', [Validators.required]),
    regular_price: new UntypedFormControl(''),
    sku: new UntypedFormControl(''),
    stock: new UntypedFormControl(1),
    description: new UntypedFormControl('', [Validators.required]),
    id: new UntypedFormControl(''),
    name: new UntypedFormControl(''),
    cancellationHours: new UntypedFormControl('', [
      Validators.required,
      Validators.min(1),
    ]),
  });
  totalAmount: number;
  config!: AngularEditorConfig;
  isSubmitted = false;
  isEligibleForCancellation: boolean = false;
  showTooltip = false;

  constructor(
    public dialogRef: DialogRef<any>,
    @Inject(DIALOG_DATA)
    public data: {
      selectedPackageData: any;
      packageOptionValues: any;
      packageOptionID: any;
      defaultPackage: any;
      categoryID: any;
    },
    public generalService: GeneralService,
    public crendentialService: CredentialService
  ) {
    if (data?.selectedPackageData) {
      this.selectedPackageData = data.selectedPackageData;
    }
    if (data?.packageOptionValues) {
      this.packageOptionValues = data.packageOptionValues;
    }
    if (data?.packageOptionID) {
      this.packageOptionID = data.packageOptionID;
    }
    if (data?.defaultPackage) {
      this.defaultPackage = data.defaultPackage;
    }
    if (data?.categoryID) {
      this.categoryID = data.categoryID;
    }
  }

  ngOnInit(): void {
    this.userDetails = this.crendentialService?.userDetails;
    this.setTaxercentageIfAvilable(this.categoryID);
    this.setPackageDetails();
    this.config = this.generalService.getEditorConfig();
    if (!this.defaultPackage) {
      this.packageData.get('sku').setValidators([Validators.required]);
    } else {
      this.packageData.get('sku').clearValidators();
    }
    this.packageData.get('sku').updateValueAndValidity();
  }

  setPackageDetails() {
    // Existing initialization
    if (this.data?.selectedPackageData?.cancellationHours) {
      this.isEligibleForCancellation = true;
      this.packageData.controls['cancellationHours'].enable();
      this.packageData.patchValue({
        cancellationHours: this.data.selectedPackageData.cancellationHours,
      });
    }

    if (this.defaultPackage) {
      this.packageData.patchValue({
        name: this.packageOptionValues?.title,
        title: this.selectedPackageData?.title,
        regular_price: this.selectedPackageData?.regular_price
          ? this.selectedPackageData?.regular_price
          : '',
        sku: this.selectedPackageData?.sku,
        description: this.selectedPackageData?.description,
        id: this.selectedPackageData?.id,
        sale_price: this.selectedPackageData?.sale_price,
        cancellationHours: this.selectedPackageData?.cancellation_time,
      });
      this.isEligibleForCancellation = this.selectedPackageData?.is_cancellable;
      this.calculateTotalAmount();
    } else {
      this.packageData.patchValue({
        name: this.packageOptionValues?.name,
        title: this.packageOptionValues?.name,
        regular_price: this.packageOptionValues?.price
          ? this.packageOptionValues?.price
          : '',
        sku: this.packageOptionValues?.sku,
        description: this.packageOptionValues?.description,
        id: this.packageOptionValues?.id,
        sale_price: this.packageOptionValues?.sale_price,
      });
      this.calculateTotalAmount();
    }
  }

  setTaxercentageIfAvilable(categoryId?: string) {
    this.taxPercentage = this.userDetails?.TAX_PERCENTAGE
      ? this.userDetails?.TAX_PERCENTAGE
      : 0;
    if (this.taxPercentage > 0 && categoryId !== null) {
      const taxExemptions: string[] = this.userDetails?.TAX_EXEMPTIONS;

      const targetIdToCheck: string = categoryId;

      const isIdPresent: boolean = taxExemptions.includes(targetIdToCheck);

      if (isIdPresent) {
        this.removeTaxPercentage = true;
        this.taxPercentage = 0;
      } else {
        this.removeTaxPercentage = false;
        this.taxPercentage = this.userDetails?.TAX_PERCENTAGE;
      }
    } else {
      this.taxPercentage = this.userDetails?.TAX_PERCENTAGE;
    }
  }

  save(): void {
    this.isSubmitted = true;

    // Dynamically update form controls and validity
    if (!this.isEligibleForCancellation) {
      // If not eligible, disable and remove validators
      this.packageData.controls['cancellationHours'].clearValidators();
      this.packageData.controls['cancellationHours'].updateValueAndValidity();
    }

    if (this.packageData.invalid) {
      this.packageData.markAllAsTouched();
      return;
    } else {
      const data = this.packageData.value;
      const packageData = {
        ...data,
        is_cancellable: this.isEligibleForCancellation,
        cancellation_time: this.isEligibleForCancellation
          ? Number(this.packageData.value.cancellationHours)
          : 0,
      };
      const obj = {
        packageOptionID: this.packageOptionID,
        packageOptionValues: packageData,
        packageData: this.selectedPackageData,
      };
      this.close(obj);
    }
  }

  checkValidPrice() {
    const regularPrice = Number(
      this.packageData.controls['regular_price'].value
    );
    const salePriceError = this.packageData.controls['sale_price']?.errors;
    const salePrice = Number(this.packageData.controls['sale_price'].value);
    if (regularPrice > 0 && regularPrice < salePrice) {
      this.packageData.controls['sale_price']?.setErrors({
        ...salePriceError,
        salePriceInvalid: true,
      });
    } else {
      if (salePriceError) {
        delete salePriceError['salePriceInvalid'];
      }
      this.packageData.controls['sale_price']?.setErrors({
        ...salePriceError,
      });
      this.calculateTotalAmount();
      this.packageData.controls['sale_price'].updateValueAndValidity();
    }
  }

  reset(): void {
    this.packageData.reset();
    this.setPackageDetails();
  }

  calculateSaleAmount() {
    let value = parseFloat(
      (this.totalAmount / (1 + this.taxPercentage / 100)).toFixed(2)
    );
    if (value) this.packageData.controls['sale_price'].setValue(value);
    else {
      this.packageData.controls['sale_price'].setValue('');
    }
    this.checkValidPrice();
  }

  maxAllowedTotal(regular_price) {
    return regular_price > 0
      ? parseInt(regular_price) +
          (this.taxPercentage / 100) * parseInt(regular_price)
      : undefined;
  }

  calculateTotalAmount() {
    const salePrice = this.packageData.get('sale_price').value;
    const taxRate = this.taxPercentage;
    const totalPrice = Number(salePrice) ? Number(salePrice) : 0;
    const taxAmount = (Number(totalPrice) * Number(taxRate)) / 100;
    const totalAmount = Number(totalPrice) + Number(taxAmount);
    this.totalAmount =
      totalAmount > 0 ? parseFloat(totalAmount.toFixed(2)) : 0.0;
  }

  get f() {
    return this.packageData.controls;
  }

  close(data?: any): void {
    this.dialogRef.close(data);
  }

  toggleCancellationEligibility(): void {
    if (this.isEligibleForCancellation) {
      // Enable and set validators for cancellationHours
      this.packageData.controls['cancellationHours'].setValidators([
        Validators.required,
        Validators.min(1),
      ]);
      this.packageData.controls['cancellationHours'].enable();
    } else {
      // Disable and clear validators for cancellationHours
      this.packageData.controls['cancellationHours'].clearValidators();
      this.packageData.controls['cancellationHours'].reset();
      this.packageData.controls['cancellationHours'].disable();
    }
    this.packageData.controls['cancellationHours'].updateValueAndValidity();
  }
}
