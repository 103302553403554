<li class="notification-item bell">
  <a
    href="javascript: void(0)"
    (click)="openModal()"
    #notificationButton
    [ngClass]="{ active: isNotificationModalOpen }"
  >
    <img src="assets/images/bell-icon.svg" alt="notification-icon" />
    <span class="{{ isNewNotificationGet ? 'notification-badge' : '' }}"></span>
  </a>
  <div
    class="notify-menu"
    #notificationDiv
    [ngClass]="{ open: isNotificationModalOpen }"
    [ngStyle]="{ display: !isNotificationModalOpen ? 'none' : 'block' }"
  >
    <div class="notify-menu-head">
      <ul class="tabs">
        <li
          class="tab-link"
          data-tab="all"
          [ngClass]="{ active: currentTab === 'all' }"
          (click)="currentTab = 'all'"
        >
          <a href="javascript:void(0);"
            >All
            <span class="count"> {{ orderNotificationsLists.length }}</span></a
          >
        </li>
        <li
          class="tab-link"
          data-tab="request"
          [ngClass]="{ active: currentTab === 'request' }"
          (click)="currentTab = 'request'"
        >
          <a href="javascript:void(0);"
            >Request
            <span class="count">
              {{ invoiceNotificationsTodos.length }}
            </span></a
          >
        </li>
      </ul>
      <!-- <div class="clear">
                    <a href="#">Clear</a>
                </div> -->
    </div>
    <div class="notify-menu-content">
      <div class="tab-info">
        <div
          id="all"
          class="tab-content"
          [ngClass]="{ current: currentTab === 'all' }"
        >
          <ul class="notification-listing" *ngIf="orderNotificationsLists">
            <li
              (click)="markAsReadNotification(item._id)"
              class="{{ !item.is_read ? 'new-notification' : '' }}"
              *ngFor="let item of orderNotificationsLists"
            >
              <div class="image">
                <img
                  *ngIf="item?.user?.customer_profile"
                  [src]="
                    item?.user?.customer_profile
                      ? item?.user?.customer_profile
                      : 'assets/images/avatar-img-1.png'
                  "
                  alt="customer-profile"
                />
              </div>
              <div class="content">
                <div class="info">
                  <!-- <h6 *ngIf="!item?.user?.fullname && !(item?.module != 'tickets')">
                    {{ item?.user?.firstname + item?.user?.lastname }}
                  </h6> -->
                  <h6
                    *ngIf="item?.user?.fullname && item?.module !== 'tickets'"
                  >
                    {{ item?.user?.fullname || '' }}
                  </h6>
                  <p>{{ item?.module_event }}</p>
                  <a
                    *ngIf="item?.notificationData[0]?.redirect_to"
                    href="javascript: void(0)"
                    (click)="
                      redirectToPage(
                        item?.notificationData[0]?.redirect_to,
                        item?.notificationData[0]?.module_id
                      )
                    "
                    class="btn t-primary-btn-outline"
                    >{{ item?.module }}
                    <span class="text-transform-none ml-1"
                      >#{{ item?.notificationData[0]?.number }}</span
                    ></a
                  >
                </div>
                <div class="dt-info">
                  <div class="dt">
                    {{
                      item?.notificationData[0]?.createdAt | date : 'dd/MM/yyyy'
                    }}
                  </div>
                  <div class="dt">
                    {{
                      item?.notificationData[0]?.createdAt | date : 'hh:mm a'
                    }}
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div *ngIf="orderNotificationsLists?.length == 0">
            No records found.
          </div>
        </div>
        <div
          id="request"
          class="tab-content"
          [ngClass]="{ current: currentTab === 'request' }"
        >
          <ul
            class="notification-listing"
            *ngIf="
              invoiceNotificationsTodos.length > 0 ||
              invoiceNotifications.length > 0
            "
          >
            <!--Todos-->
            <li
              *ngFor="let item of invoiceNotificationsTodos"
              [ngClass]="{ 'with-timeline': item?.invoice_sent_date }"
            >
              <div class="image">
                <img
                  *ngIf="item?.customer_profile"
                  [src]="
                    item?.customer_profile
                      ? item?.customer_profile
                      : 'assets/images/avatar-img-1.png'
                  "
                  alt="customer_profile"
                />
              </div>
              <div class="content">
                <div class="info">
                  <h6>{{ item?.customer_name }}</h6>
                  <p *ngIf="!item?.invoice_sent_date">Requested for invoice</p>
                  <div class="date">
                    {{
                      item?.requested_date
                        | date
                          : 'dd MMMM
                                        yyyy'
                    }}
                  </div>
                </div>
                <button
                  class="btn t-primary-btn"
                  (click)="sendInvoice()"
                  [routerLink]="['/home/support/invoice-request-tickets']"
                  *ngIf="!item?.invoice_sent_date"
                >
                  Send Invoice
                </button>
              </div>
            </li>
            <!--Done-->
            <li
              *ngFor="let item of invoiceNotifications"
              [ngClass]="{ 'with-timeline': item?.invoice_sent_date }"
            >
              <div class="image">
                <img
                  *ngIf="item?.customer_profile"
                  [src]="
                    item?.customer_profile
                      ? item?.customer_profile
                      : 'assets/images/avatar-img-1.png'
                  "
                  alt="customer_profile"
                />
              </div>
              <div class="content">
                <div class="info">
                  <h6>{{ item?.customer_name }}</h6>
                  <p *ngIf="!item?.invoice_sent_date; else invoiceSentBlock">
                    Requested for invoice
                  </p>
                  <ng-template #invoiceSentBlock>
                    <div
                      class="timeline-request"
                      *ngIf="item?.invoice_sent_date"
                    >
                      <ul>
                        <li>
                          <p>Requested for invoice</p>
                          <span class="date">{{
                            item?.requested_date
                              | date
                                : 'dd MMMM
                                                        yyyy'
                          }}</span>
                        </li>
                        <li class="completed">
                          <p>Invoice Send</p>
                          <span class="date">{{
                            item?.invoice_sent_date
                              | date
                                : 'dd MMMM
                                                        yyyy'
                          }}</span>
                        </li>
                      </ul>
                    </div>
                  </ng-template>
                </div>
              </div>
            </li>
          </ul>
          <div
            *ngIf="
              invoiceNotifications?.length == 0 &&
              invoiceNotificationsTodos?.length == 0
            "
          >
            No records found.
          </div>
        </div>
        <div
          class="view-all-list"
          *ngIf="totalInvCount >= 5 || totalNotiCount >= 5"
        >
          <a
            href="javascript: void(0)"
            (click)="closeNotifyModal()"
            [routerLink]="['/home/all-notifications']"
            >Go to Notifications</a
          >
        </div>
      </div>
    </div>
  </div>
</li>
