<div
  class="custom-dialog-modal modal-xl modal-dialog modal-dialog-centered modal-dialog-scrollable"
  role="document"
>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">Ticket #{{ ticketDetails?.ticket_number }}</h4>
      <button type="button" class="close" (click)="closeModal()">
        <img src="assets/images/close-outline.svg" alt="close" />
      </button>
    </div>
    <div class="modal-body">
      <div class="add-new-ticket-block" [formGroup]="invRequestData">
        <div class="request-invoice-ticket ticket-chat-thread">
          <div class="head-info">
            <div class="personal-info">
              <h5 class="name" *ngIf="ticketDetails?.full_name">
                {{ ticketDetails?.full_name }}
              </h5>
              <ul>
                <li *ngIf="ticketDetails?.email">{{ ticketDetails?.email }}</li>
                <li *ngIf="ticketDetails?.contact?.internationalNumber">
                  {{ ticketDetails?.contact?.internationalNumber }}
                </li>
              </ul>
              <p
                class="service-name mt-2 mb-0"
                *ngIf="ticketDetails?.serviceName"
              >
                <label class="font-weight-bold">Service:</label>
                <span> {{ ticketDetails?.serviceName }}</span>
              </p>
            </div>
            <div class="ordernumber" *ngIf="ticketDetails?.order_number">
              <a
                class="btn"
                [routerLink]="['/home/service/order-details']"
                [queryParams]="{ oid: ticketDetails?.orderId }"
                target="_blank"
              >
                Order Number #{{ ticketDetails?.order_number }}
              </a>
              <!-- Order Number #{{ ticketDetails?.order_number }} -->
            </div>
          </div>
          <div class="thread-block">
            <div class="date">
              {{ ticketDetails?.createdAt | date : 'dd/MM/yyyy' }}
            </div>
            <h4 class="title">{{ ticketDetails?.subject }}</h4>
            <p class="desc" [innerHtml]="ticketDetails?.description"></p>
            <div class="image-row">
              <div
                class="img-item"
                *ngFor="let i of ticketDetails?.ticketfiles"
              >
                <a
                  type="button"
                  class="download-btn"
                  (click)="
                    generalService.downloadFile(
                      i.img_url,
                      i.file_name,
                      i.trimmed_file_name
                    )
                  "
                >
                  <img
                    class="ticket-img mb-1"
                    *ngIf="
                      i.img_url &&
                      !(
                        i.file_type === 'pdf' ||
                        i.file_type === 'doc' ||
                        i.file_type === 'docx' ||
                        i.file_type === 'mp4' ||
                        i.file_type === 'mov' ||
                        i.file_type === 'avi' ||
                        i.file_type === 'wvm' ||
                        i.file_type === 'webm' ||
                        i.file_type === 'ogg' ||
                        i.file_type === 'mpeg' ||
                        i.file_type === '3gpp'
                      )
                    "
                    src="{{ i.img_url }}"
                    alt="ticket-img"
                  />
                </a>

                <!-- <img
                  *ngIf="
                    i.img_url &&
                    !(
                      i.file_type === 'pdf' ||
                      i.file_type === 'doc' ||
                      i.file_type === 'docx' ||
                      i.file_type === 'mp4' ||
                      i.file_type === 'mov' ||
                      i.file_type === 'avi' ||
                      i.file_type === 'wvm' ||
                      i.file_type === 'webm' ||
                      i.file_type === 'ogg' ||
                      i.file_type === 'mpeg' ||
                      i.file_type === '3gpp'
                    )
                  "
                  src="{{ i.img_url }}"
                  alt="ticket-img"
                /> -->
                <!-- Link for pdf, doc, or docx files with icons -->
                <a
                  *ngIf="
                    i.img_url &&
                    (i.file_type === 'pdf' ||
                      i.file_type === 'doc' ||
                      i.file_type === 'docx')
                  "
                  style="cursor: pointer"
                  (click)="
                    generalService.downloadFile(
                      i.img_url,
                      i.file_name,
                      i.trimmed_file_name
                    )
                  "
                >
                  <img
                    *ngIf="i.file_type === 'pdf'"
                    src="assets/images/ticket-pdf-icon.svg"
                    alt="pdf-icon"
                  />
                  <img
                    *ngIf="i.file_type === 'doc' || i.file_type === 'docx'"
                    src="assets/images/ticket-doc-ic.png"
                    alt="doc-icon"
                  />
                </a>
                <!-- Video file display -->

                <video
                  *ngIf="
                    i.img_url &&
                    (i.file_type === 'mp4' ||
                      i.file_type === 'webm' ||
                      i.file_type === 'avi' ||
                      i.file_type === 'wvm' ||
                      i.file_type === 'mov' ||
                      i.file_type === 'webm' ||
                      i.file_type === 'ogg' ||
                      i.file_type === 'mpeg' ||
                      i.file_type === '3gpp')
                  "
                  [src]="i.img_url"
                  controls
                  width="100%"
                  height="100%"
                  alt="ticket-video"
                ></video>
                <!-- src="assets/images/pin-attach-icon.svg" -->

                <!-- <a href="{{ i.img_url }}" target="_blank">
                  <img
                    *ngIf="i.img_url && i.file_type === 'pdf'"
                    src="assets/images/ticket-pdf-icon.svg"
                    alt="ticket-img"
                /></a> -->
                <!-- <span class="count">4</span> -->
              </div>
            </div>
          </div>
        </div>
        <div class="form-group chat-window-modal mb-0">
          <!-- <angular-editor formControlName="description" [config]="config">
                </angular-editor>
                <div *ngIf="f['description'].invalid && (f['description'].touched)"
                    class="cross-validation-error-message">
                    <div *ngIf="f['description'].errors && f['description'].errors['required']"
                        class="required-note text-danger">
                        Description is required.
                    </div>
                </div> -->
          <app-ticket-chat-configuration-modal
            (imgUploaded)="getTicketDetails()"
            [chatModalObject]="chatModalObject"
          ></app-ticket-chat-configuration-modal>
        </div>
        <!-- <h2>from ticketDetails: status: {{ticketDetails?.status}}</h2> -->

        <div class="bottom-btnrow mt-0" *ngIf="ticketDetails?.status == 1">
          <button
            class="btn t-primary-btn"
            (click)="reopenTicket(ticketDetails?._id)"
          >
            Reopen Ticket
          </button>
        </div>

        <!-- <div class="bottom-btnrow" (click)="sendInvoiceRequest()">
                <button class="btn t-primary-btn"><img src="assets/images/paper-plane-outline.svg"> Send</button>
            </div> -->
      </div>
    </div>
  </div>
</div>
