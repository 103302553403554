import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  Component,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { OpenModalConfig } from '@app/shared/directives/open-modal.directive';

import { AngularEditorConfig } from '@kolkov/angular-editor';
import { takeUntil } from 'rxjs';
import { GeneralService } from 'src/app/core/services/general.service';
import { SupportService } from 'src/app/core/services/support.service';
import { SubscriptionDisposer } from 'src/app/shared/helpers/subscription-disposer';

@Component({
  selector: 'app-ticket-invoice-request-modal',
  templateUrl: './ticket-invoice-request-modal.component.html',
})
export class TicketInvoiceRequestModalComponent
  extends SubscriptionDisposer
  implements OnInit, OnChanges
{
  ticketID: any;
  chatModalObject!: any;
  ticketDetails: any;
  config!: AngularEditorConfig;
  invRequestData = new UntypedFormGroup({
    description: new UntypedFormControl('', [Validators.required]),
  });

  customChatModalData: OpenModalConfig = {
    modalComponent: TicketInvoiceRequestModalComponent,
    modalData: {
      ...this.chatModalObject,
    },
    width: '83rem',
  };

  constructor(
    public generalService: GeneralService,
    private supportService: SupportService,
    public dialogRef: DialogRef<any>,
    private dialog: MatDialog,
    private renderer: Renderer2,
    @Inject(DIALOG_DATA)
    public data: any
  ) {
    super();

    this.ticketID = data.ticketID;
    this.chatModalObject = data.chatModalObject;
  }

  get f() {
    return this.invRequestData.controls;
  }

  ngOnInit(): void {
    if (this.ticketID) {
      this.getTicketDetails();
    }
    this.config = {
      ...this.generalService.getEditorConfig(),
      toolbarPosition: 'bottom',
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.ticketID) {
      this.getTicketDetails();
    }
    if (changes['ticketDetails'] && changes['ticketDetails'].currentValue) {
      this.ticketDetails = changes['ticketDetails'].currentValue;
      this.ticketDetails = this.ticketDetails;
      this.getTicketDetails();
    }
  }

  getTicketDetails(): void {
    const payload = {
      ticketID: this.ticketID,
    };
    this.supportService
      .getTicketDetails(payload)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res) => {
          if (res) {
            this.ticketDetails = res?.data;
            // console.log('this.ticketDetails --------')
            // console.log(this.ticketDetails)
            this.ticketDetails?.ticketfiles?.map((item: any) => {
              let arr = [];
              arr = item.file_name?.split('.');
              item.file_type = arr?.[arr.length - 1];

              // Add trimmed file name logic
              if (item.file_name) {
                item.trimmed_file_name = item.file_name.replace(
                  /_(?!.*_).*$/,
                  ''
                );
              }
            });
            // console.log('this.ticketDetails');
            // console.log(this.ticketDetails);
          }
        },
        (error) => {
          this.generalService.displayError(error?.error?.message);
        }
      );
  }

  sendInvoiceRequest(): void {
    if (this.invRequestData.invalid) {
      this.invRequestData.markAllAsTouched();
      return;
    } else {
      const payload = {
        ticketID: this.ticketID,
        invoice_request_description: this.invRequestData.value.description,
      };
      this.supportService
        .ticketInvoiceRequestSend(payload)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (res) => {
            if (res) {
              this.generalService.displaySuccess(res?.message);
              this.closeModal();
            }
          },
          (error) => {
            this.generalService.displayError(error?.error?.message);
          }
        );
    }
  }

  closeModal(): void {
    if (this.data?.onModalClose) {
      this.data.onModalClose(); // Call the callback function
    }
    this.dialogRef.close();
  }

  reopenTicket(ticketId: any): void {
    const payload = {
      ticketID: ticketId,
    };

    // If canReopen true, show confirm & call reOpen api
    if (this.ticketDetails?.canReopen) {
      this.generalService
        .confirmationDialog('Are You Sure?', `You want to reopen this ticket!`)
        .then((result: any) => {
          if (result.isConfirmed) {
            this.reopenAPICall(payload);
          }
        });
    }
    // If canReopen false, call reopen api
    else {
      this.reopenAPICall(payload);
    }
  }

  reopenAPICall(payload: any): void {
    this.supportService.reopenTicket(payload).subscribe(
      (res) => {
        if (res) {
          if (res?.success) {
            this.generalService.displaySuccess(res?.message);

            // After saving the ticket, emit the tab change
            this.supportService.emitTabChange('open');
          } else {
            // handle reopend 2 cases
            if (res?.data) {
              // handle case
              this.generalService
                .alertDialog('Ticket cannot be reopened', `${res?.message}`)
                .then((result: any) => {
                  if (result.isConfirmed) {
                    this.openTicketModal(res?.data?.ticket_id);
                    this.supportService.emitTabChange('closed');

                    // handle tab change
                    if (res?.data?.status === 'open') {
                      this.supportService.emitTabChange('open');
                    } else {
                      this.supportService.emitTabChange('closed');
                    }
                  }
                  if (result.isDismissed) {
                  }
                });
            } else {
              this.generalService.displayError(res?.message);
            }
          }
          this.closeModal();
        }
      },
      (error) => {
        this.generalService.displayError(error?.error?.message);
      }
    );
  }

  openTicketModal(ticketID: any): void {
    const payload = {
      ticketID: ticketID,
    };
    this.supportService
      .getTicketDetails(payload)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res) => {
          if (res) {
            let ticketDetailsv2 = res?.data;
            ticketDetailsv2?.ticketfiles?.map((item: any) => {
              let arr = [];
              arr = item.file_name?.split('.');
              item.file_type = arr?.[arr.length - 1];
            });
            const modalConfig = this.getInvoiceModalConfig(
              ticketID,
              ticketDetailsv2
            );
            console.log('modalConfig');
            console.log(modalConfig);
            this.openModal(modalConfig);
          }
        },
        (error) => {
          this.generalService.displayError(error?.error?.message);
        }
      );
  }

  getInvoiceModalConfig(id: any, ticket: any) {
    this.ticketID = id;
    this.chatModalObject = {
      ...ticket,
      isOpen: ticket.status === 0 || ticket.status === 2 ? 'open' : 'closed',
    };
    // console.log('this.chatModalObject')
    // console.log(this.chatModalObject)

    this.customChatModalData = {
      ...this.customChatModalData,
      modalData: {
        chatModalObject: { ...this.chatModalObject },
        ticketID: this.ticketID,
      },
    };

    return this.customChatModalData;
  }

  openModal(config: OpenModalConfig): void {
    const dialogRef = this.dialog.open(config.modalComponent, {
      data: config.modalData,
      width: config.width,
      maxHeight: '90vh',
    });

    dialogRef.afterOpened().subscribe(() => {
      const container = document.querySelector(
        '.mat-dialog-container'
      ) as HTMLElement;
      if (container) {
        this.renderer.setStyle(container, 'background', 'transparent');
        this.renderer.setStyle(container, 'boxShadow', 'none');
        this.renderer.setStyle(container, 'overflowY', 'auto');
        this.renderer.setStyle(container, 'padding', '0');
      }

      const modalContainer = document.querySelector(
        '.custom-dialog-modal'
      ) as HTMLElement;
      if (modalContainer) {
        this.renderer.addClass(modalContainer, 'opened-programatically');
      }
    });

    dialogRef.afterClosed().subscribe(() => {
      console.log('modal clsoed');
      // Reset ticket details to avoid retaining old state
      this.ticketDetails = null;
      this.ticketID = null;

      const modalContainer = document.querySelector(
        '.custom-dialog-modal'
      ) as HTMLElement;
      if (modalContainer) {
        this.renderer.removeClass(modalContainer, 'opened-programatically');
      }
    });
  }
}
